import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "../components/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Navbar from "../components/nav/Nav";
import Footer from "../components/nav/Footer";
import { Formik, Form } from "formik";
import { loadRecaptcha, SITE_KEY } from "../helper";
import MyTextField from "../components/fields/MyTextField";

import MyCheckboxGroup from "../components/fields/MyCheckboxGroup";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { Paper } from "@material-ui/core";
import { handleRegister } from "../api";
import { navigate } from "gatsby";
import { lighten } from "@material-ui/core/styles";
import Loading from "../components/Loading";
import { Alert, AlertTitle } from "@material-ui/lab";
import { privateScheme, companyScheme } from "../helper/schemes/signUpScheme";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { BASE_URL } from "../api/config";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { texts } from "../data/texts";

const companyInitialValues = {
  first_name: "",
  last_name: "",
  email: "",
  tax_number: "",
  company: "",
  street: "",
  postal_code: "",
  city: "",
  password: "",
  password_confirmation: "",
  user_type: process.env.GATSBY_BRANDING,
  privacy: [],
  revocation: [],
  site_ref: process.env.GATSBY_SITE,
};
const privateInitialValues = {
  first_name: "",
  last_name: "",
  email: "",
  street: "",
  postal_code: "",
  city: "",
  password: "",
  password_confirmation: "",
  user_type: process.env.GATSBY_BRANDING,
  privacy: [],
  revocation: [],
  site_ref: process.env.GATSBY_SITE,
};

const useStyles = makeStyles((theme) => ({
  login: {
    paddingTop: "0.5rem",
    minHeight: "80vh",
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
    textAlign: "center",
    border: "none",
  },
  avatar: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
    fontSize: "50px",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 1, 2),
    color: theme.palette.primary.light,
    width: "100%",
    background: theme.palette.primary.main,
    "&:hover": {
      background: lighten(theme.palette.primary.main, 0.2),
    },
  },
  back: {
    margin: theme.spacing(3, 1, 2),
    width: "100%",
    borderColor: "#144c44",
    color: "#053c27",
    "&:hover": {
      backgroundColor: "#053c27",
      color: "#fff",
      borderColor: "#053c27",
    },
  },
  btn: {
    textAlign: "center",
  },
  agb: {
    textAlign: "start",
  },
  links: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    fontWeight: "bold",
  },
  datenschutzTypo: {
    lineHeight: "1",
  },
  captchaLink: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    fontWeight: "semi-bold",
  },
  recaptchaText: {
    fontSize: "0.6rem",
  },
}));

const Message = ({ type, title, message, onClose }) => {
  return (
    <Box my={3} align="start">
      <Alert onClose={onClose} severity={type}>
        <AlertTitle variant="h6">{title}</AlertTitle>
        <Typography variant="body1" style={{ whiteSpace: "pre-wrap" }}>
          {message}
        </Typography>
      </Alert>
    </Box>
  );
};

const Register = ({ location }) => {
  const classes = useStyles();
  const [dirty, setDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(true);
  const [emailAlreadyTakenMsg, setEmailAlreadyTakenMsg] = useState(false);
  const [affiliateHash, setAffiliateHash] = useState(null);
  const [dealer, setDealer] = useState(null);
  const { trackPageView } = useMatomo();
  const [privateForm, setPrivateForm] = useState(false);
  const [companyForm, setCompanyForm] = useState(false);

  const [dialog, setDialog] = useState(false);

  const handleCompany = () => {
    setCompanyForm(true);
    setPrivateForm(false);
  };
  const handlePrivate = () => {
    setCompanyForm(false);
    setPrivateForm(true);
  };
  const handleDialog = () => {
    setDialog(true);
  };
  const handleBack = () => {
    setDialog(false);
  };

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    setAffiliateHash(params.get("hash"));
  }, [location]);

  React.useEffect(() => {
    if (affiliateHash) {
      setLoading(true);
      fetch(`${BASE_URL}/thg/index/${affiliateHash}`)
        .then((resp) => resp.json())
        .then((json) => json?.data)
        .then((json) => setDealer(json))
        .finally(() => setLoading(false));
    }
  }, [affiliateHash]);

  React.useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  const submitData = (data, formikProps) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(SITE_KEY, { action: "thgregister" })
        .then((token) => requestToServer(data, token, formikProps));
    });
  };

  const requestToServer = (data, token, formikProps) => {
    setLoading(true);
    const emailAlreadyTakenCallback = () => {
      setEmailAlreadyTakenMsg(true);
      setLoading(false);
    };
    const callback = () => {
      setLoading(false);
      if (typeof window !== "undefined") {
        navigate("/login");
      }
    };
    handleRegister(
      data,
      token,
      affiliateHash,
      emailAlreadyTakenCallback,
      callback
    );
  };

  useEffect(() => {
    setShowMessage(true);
  }, []);

  useEffect(() => {
    if (dirty) {
      loadRecaptcha();
    }
  }, [dirty]);

  const handleSave = (values, formikProps) => {
    //submit the form data
    submitData(values, formikProps);
    handleBack();
  };

  if (loading) return <Loading />;

  return (
    <>
      <Navbar />
      <Container
        component="main"
        maxWidth="sm"
        style={{ paddingTop: "3rem", minHeight: "75vh" }}
      >
        <CssBaseline />
        <Paper variant="outlined" className={classes.paper}>
          <AccountCircleIcon className={classes.avatar} />
          <Typography component="h1" variant="h5">
            Registrieren
          </Typography>
          {dealer && (
            <Message
              type="info"
              title="Info"
              message={`${dealer.first_name} ${dealer.last_name} hat Sie eingeladen unseren THG-Quotenservice zu nutzen. Dadurch entstehen für Sie keine Kosten sondern nur Zusatzerlöse.`}
              onClose={() => setDealer(null)}
            />
          )}

          {emailAlreadyTakenMsg && (
            <Message
              type="error"
              title={texts["error"]}
              message={texts["emailInUse"]}
              onClose={() => setEmailAlreadyTakenMsg(false)}
            />
          )}
          <Grid item xs={12} style={{ marginTop: 30 }}>
            <Typography align="left">
              <b>Hinweis</b> * Bitte achten Sie darauf, dass Ihr Anmeldename und
              der Name auf der Zulassungsbescheinigung (Teil I) übereinstimmen
              müssen!
              <p>
                Wählen Sie bitte, ob Sie sich als Privatperson oder als
                Unternehmen registrieren.
              </p>
            </Typography>
          </Grid>
          <Grid container>
            <Grid justifyContent>
              <Button
                variant="outlined"
                color="primary"
                className={classes.submit}
                style={{
                  marginLeft: 5,
                  marginTop: 20,
                  width: 233,
                }}
                onClick={handlePrivate}
              >
                Privatperson
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                className={classes.submit}
                style={{
                  marginLeft: 15,
                  marginTop: 20,
                  width: 233,
                }}
                onClick={handleCompany}
              >
                Unternehmen
              </Button>
            </Grid>
          </Grid>

          {privateForm == true ? (
            <div id="privateForm">
              <Typography
                align="left"
                component="h5"
                variant="h6"
                style={{
                  marginTop: 10,
                }}
              >
                Registrierung für Privatpersonen
              </Typography>

              <Formik
                initialValues={privateInitialValues}
                validationSchema={privateScheme}
                onSubmit={(values, formikProps) => {
                  // submitData(values, formikProps);

                  if (values) handleDialog(true);
                }}
              >
                {(props) => {
                  if (!dirty && props.dirty) {
                    setDirty(true);
                  }
                  return (
                    <div>
                      <Form className={classes.login}>
                        <div>
                          <Dialog open={dialog}>
                            <DialogTitle>Privatperson</DialogTitle>
                            <DialogContent>
                              <Box align="start" style={{ width: "100%" }}>
                                <Alert severity="info">
                                  <AlertTitle variant="h6">
                                    Bitte beachten!
                                  </AlertTitle>
                                  <Typography
                                    variant="body1"
                                    style={{ whiteSpace: "pre-wrap" }}
                                  >
                                    Nach der Registrierung erhalten Sie von uns
                                    einen Bestätigungslink an die angegebene
                                    E-Mail-Adresse. Überprüfen Sie ggf. auch
                                    Ihren Spam-Ordner.
                                  </Typography>
                                </Alert>
                              </Box>
                              <DialogContentText>
                                <Grid
                                  container
                                  spacing={2}
                                  style={{ marginTop: 10 }}
                                >
                                  <Grid item xs={6} sm={6}>
                                    <MyTextField
                                      disabled
                                      fullWidth
                                      id="first_name"
                                      label="Vorname"
                                      name="first_name"
                                      value={props.values.first_name}
                                    />
                                  </Grid>
                                  <Grid item xs={6} sm={6}>
                                    <MyTextField
                                      disabled
                                      fullWidth
                                      id="last_name"
                                      label="Nachnamen"
                                      name="last_name"
                                      value={props.values.last_name}
                                    />
                                  </Grid>
                                  <Grid item xs={6} sm={6}>
                                    <MyTextField
                                      disabled
                                      fullWidth
                                      id="email"
                                      label="Email Adresse"
                                      name="email"
                                      value={props.values.email}
                                    />
                                  </Grid>
                                  <Grid item xs={6} sm={6}>
                                    <MyTextField
                                      disabled
                                      fullWidth
                                      id="street"
                                      label="Straße"
                                      name="street"
                                      value={props.values.street}
                                    />
                                  </Grid>
                                  <Grid item xs={6} sm={6}>
                                    <MyTextField
                                      disabled
                                      fullWidth
                                      id="postal_code"
                                      label="Postleitzahl"
                                      name="postal_code"
                                      value={props.values.postal_code}
                                    />
                                  </Grid>
                                  <Grid item xs={6} sm={6}>
                                    <MyTextField
                                      disabled
                                      fullWidth
                                      id="city"
                                      label="Ort"
                                      name="city"
                                      value={props.values.city}
                                    />
                                  </Grid>
                                </Grid>
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Box
                                style={{
                                  display: "flex",
                                  margin: "auto",
                                }}
                              >
                                <Button
                                  onClick={handleBack}
                                  variant="outlined"
                                  className={classes.back}
                                >
                                  zurück
                                </Button>

                                <Button
                                  variant="contained"
                                  onClick={() =>
                                    handleSave(props.values, props.formikProps)
                                  }
                                  className={classes.submit}
                                >
                                  speichern
                                </Button>
                              </Box>
                            </DialogActions>
                          </Dialog>
                          <div className={classes.form} noValidate>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <MyTextField
                                  autoComplete="fname"
                                  name="first_name"
                                  fullWidth
                                  id="first_name"
                                  label="Vorname"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <MyTextField
                                  fullWidth
                                  id="last_name"
                                  label="Nachname"
                                  name="last_name"
                                  autoComplete="lname"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <MyTextField
                                  fullWidth
                                  id="email"
                                  label="Email Adresse"
                                  name="email"
                                  autoComplete="email"
                                />
                              </Grid>

                              <Grid item xs={12}>
                                <MyTextField
                                  fullWidth
                                  id="street"
                                  label="Straße/Hausnummer"
                                  name="street"
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <MyTextField
                                  fullWidth
                                  id="postal_code"
                                  label="Postleitzahl"
                                  name="postal_code"
                                  autoComplete="postal-code"
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <MyTextField
                                  fullWidth
                                  id="city"
                                  label="Ort"
                                  name="city"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <MyTextField
                                  fullWidth
                                  name="password"
                                  label="Passwort"
                                  type="password"
                                  id="password"
                                  autoComplete="current-password"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <MyTextField
                                  fullWidth
                                  name="password_confirmation"
                                  label="Passwort wiederholen"
                                  type="password"
                                  id="password_confirmation"
                                  autoComplete="current-password"
                                />
                              </Grid>

                              <Grid item xs={12} className={classes.agb}>
                                <MyCheckboxGroup
                                  label=""
                                  options={[
                                    {
                                      label: (
                                        <Box style={{ textAlign: "left" }}>
                                          <Typography variant="overline">
                                            Hiermit stimme ich den{" "}
                                            <Link
                                              href="/agb"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className={classes.links}
                                            >
                                              AGB
                                            </Link>{" "}
                                            zu.
                                          </Typography>
                                        </Box>
                                      ),
                                      value: "accepted",
                                    },
                                  ]}
                                  name="privacy"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <MyCheckboxGroup
                                  label=""
                                  options={[
                                    {
                                      label: (
                                        <Box
                                          style={{ textAlign: "left" }}
                                          className={classes.datenschutz}
                                        >
                                          <Typography
                                            variant="overline"
                                            className={classes.datenschutzTypo}
                                          >
                                            Hiermit bestätige ich, dass ich die{" "}
                                            <Link
                                              href="/widerrufsbelehrung"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className={classes.links}
                                            >
                                              Wiederrufsbelehrung
                                            </Link>{" "}
                                            und die
                                            <Link
                                              href="/datenschutz"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className={classes.links}
                                            >
                                              {" "}
                                              Datenschutzerklärung
                                            </Link>{" "}
                                            zur Kenntnis genommen habe
                                          </Typography>
                                        </Box>
                                      ),
                                      value: "accepted",
                                    },
                                  ]}
                                  name="revocation"
                                />
                              </Grid>
                            </Grid>
                            <Grid item className={classes.btn} container>
                              <Grid item xs={3} />
                              <Grid item xs={6}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  className={classes.submit}
                                  type="submit"
                                >
                                  Registrieren
                                </Button>
                                <Box
                                  textAlign="center"
                                  display="inline-block"
                                  mt={0}
                                >
                                  <Typography
                                    className={classes.recaptchaText}
                                    variant="caption"
                                    display="inline-block"
                                  >
                                    This site is protected by reCAPTCHA and the
                                    Google{" "}
                                    <Link
                                      href="https://policies.google.com/privacy"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className={classes.captchaLink}
                                    >
                                      Privacy Policy
                                    </Link>{" "}
                                    and{" "}
                                    <Link
                                      href="https://policies.google.com/terms"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className={classes.captchaLink}
                                    >
                                      Terms of Service
                                    </Link>{" "}
                                    apply.
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={3} />
                            </Grid>
                            <br />
                            <Grid container justifyContent="center">
                              <Grid item>
                                <Link
                                  href="/login"
                                  variant="body2"
                                  className={classes.links}
                                >
                                  Sie besitzen bereits einen Account? Einloggen
                                </Link>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </Form>
                    </div>
                  );
                }}
              </Formik>
            </div>
          ) : null}
          {companyForm == true ? (
            <div id="companyForm">
              <Typography
                align="left"
                component="h5"
                variant="h6"
                style={{
                  marginTop: 10,
                }}
              >
                Registrierung für Unternehmen
              </Typography>
              <Formik
                initialValues={companyInitialValues}
                validationSchema={companyScheme}
                onSubmit={(values, formikProps) => {
                  // submitData(values, formikProps);
                  if (values) handleDialog(true);
                }}
              >
                {(props) => {
                  if (!dirty && props.dirty) {
                    setDirty(true);
                  }
                  return (
                    <div>
                      <Form className={classes.login}>
                        <div>
                          <Dialog open={dialog}>
                            <DialogTitle>Unternehmen</DialogTitle>
                            <DialogContent>
                              <Box
                                my={3}
                                align="start"
                                style={{ width: "100%" }}
                              >
                                <Alert severity="info">
                                  <AlertTitle variant="h6">
                                    Bitte beachten!
                                  </AlertTitle>
                                  <Typography
                                    variant="body1"
                                    style={{ whiteSpace: "pre-wrap" }}
                                  >
                                    Nach der Registrierung erhalten Sie von uns
                                    einen Bestätigungslink an die angegebene
                                    E-Mail-Adresse. Überprüfen Sie ggf. auch
                                    Ihren Spam-Ordner.
                                  </Typography>
                                </Alert>
                              </Box>
                              <DialogContentText>
                                <Grid container spacing={2}>
                                  <Grid item xs={6} sm={6}>
                                    <MyTextField
                                      disabled
                                      fullWidth
                                      id="first_name"
                                      label="Vornamen"
                                      name="first_name"
                                      value={props.values.first_name}
                                    />
                                  </Grid>
                                  <Grid item xs={6} sm={6}>
                                    <MyTextField
                                      disabled
                                      fullWidth
                                      id="last_name"
                                      label="Nachnamen"
                                      name="last_name"
                                      value={props.values.last_name}
                                    />
                                  </Grid>
                                  <Grid item xs={6} sm={6}>
                                    <MyTextField
                                      disabled
                                      fullWidth
                                      id="email"
                                      label="Email Adresse"
                                      name="email"
                                      value={props.values.email}
                                    />
                                  </Grid>
                                  <Grid item xs={6} sm={6}>
                                    <MyTextField
                                      disabled
                                      fullWidth
                                      id="company"
                                      label="Firmennamen"
                                      name="company"
                                      value={props.values.company}
                                    />
                                  </Grid>
                                  <Grid item xs={6} sm={6}>
                                    <MyTextField
                                      disabled
                                      fullWidth
                                      id="tax_number"
                                      label="USt-IdNr. oder Steuernummer"
                                      name="tax_number"
                                      value={props.values.tax_number}
                                    />
                                  </Grid>
                                  <Grid item xs={6} sm={6}>
                                    <MyTextField
                                      disabled
                                      fullWidth
                                      id="street"
                                      label="Straße"
                                      name="street"
                                      value={props.values.street}
                                    />
                                  </Grid>
                                  <Grid item xs={6} sm={6}>
                                    <MyTextField
                                      disabled
                                      fullWidth
                                      id="postal_code"
                                      label="Postleitzahl"
                                      name="postal_code"
                                      value={props.values.postal_code}
                                    />
                                  </Grid>
                                  <Grid item xs={6} sm={6}>
                                    <MyTextField
                                      disabled
                                      fullWidth
                                      id="city"
                                      label="Ort"
                                      name="city"
                                      value={props.values.city}
                                    />
                                  </Grid>
                                </Grid>
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Box
                                style={{
                                  display: "flex",
                                  margin: "auto",
                                }}
                              >
                                <Button
                                  onClick={handleBack}
                                  variant="outlined"
                                  className={classes.back}
                                >
                                  zurück
                                </Button>

                                <Button
                                  variant="contained"
                                  onClick={() =>
                                    handleSave(props.values, props.formikProps)
                                  }
                                  className={classes.submit}
                                >
                                  speichern
                                </Button>
                              </Box>
                            </DialogActions>
                          </Dialog>

                          <div className={classes.form} noValidate>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <MyTextField
                                  autoComplete="fname"
                                  name="first_name"
                                  fullWidth
                                  id="first_name"
                                  label="Vorname"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <MyTextField
                                  fullWidth
                                  id="last_name"
                                  label="Nachname"
                                  name="last_name"
                                  autoComplete="lname"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <MyTextField
                                  id="email"
                                  label="Email Adresse"
                                  name="email"
                                  autoComplete="email"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <MyTextField
                                  fullWidth
                                  id="company"
                                  label="Firma"
                                  name="company"
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <MyTextField
                                  fullWidth
                                  id="tax_number"
                                  label="USt-IdNr. oder Steuernummer"
                                  name="tax_number"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <MyTextField
                                  fullWidth
                                  id="street"
                                  label="Straße/Hausnummer"
                                  name="street"
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <MyTextField
                                  fullWidth
                                  id="postal_code"
                                  label="Postleitzahl"
                                  name="postal_code"
                                  autoComplete="postal-code"
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <MyTextField
                                  fullWidth
                                  id="city"
                                  label="Ort"
                                  name="city"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <MyTextField
                                  fullWidth
                                  name="password"
                                  label="Passwort"
                                  type="password"
                                  id="password"
                                  autoComplete="current-password"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <MyTextField
                                  fullWidth
                                  name="password_confirmation"
                                  label="Passwort wiederholen"
                                  type="password"
                                  id="password_confirmation"
                                  autoComplete="current-password"
                                />
                              </Grid>

                              <Grid item xs={12} className={classes.agb}>
                                <MyCheckboxGroup
                                  label=""
                                  options={[
                                    {
                                      label: (
                                        <Box style={{ textAlign: "left" }}>
                                          <Typography variant="overline">
                                            Hiermit stimme ich den{" "}
                                            <Link
                                              href="/agb"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className={classes.links}
                                            >
                                              AGB
                                            </Link>{" "}
                                            zu.
                                          </Typography>
                                        </Box>
                                      ),
                                      value: "accepted",
                                    },
                                  ]}
                                  name="privacy"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <MyCheckboxGroup
                                  label=""
                                  options={[
                                    {
                                      label: (
                                        <Box
                                          style={{ textAlign: "left" }}
                                          className={classes.datenschutz}
                                        >
                                          <Typography
                                            variant="overline"
                                            className={classes.datenschutzTypo}
                                          >
                                            Hiermit bestätige ich, dass ich die{" "}
                                            <Link
                                              href="/widerrufsbelehrung"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className={classes.links}
                                            >
                                              Wiederrufsbelehrung
                                            </Link>{" "}
                                            und die
                                            <Link
                                              href="/datenschutz"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className={classes.links}
                                            >
                                              {" "}
                                              Datenschutzerklärung
                                            </Link>{" "}
                                            zur Kenntnis genommen habe
                                          </Typography>
                                        </Box>
                                      ),
                                      value: "accepted",
                                    },
                                  ]}
                                  name="revocation"
                                />
                              </Grid>
                            </Grid>
                            <Grid item className={classes.btn} container>
                              <Grid item xs={3} />
                              <Grid item xs={6}>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  className={classes.submit}
                                >
                                  Registrieren
                                </Button>
                                <Box
                                  textAlign="center"
                                  display="inline-block"
                                  mt={0}
                                >
                                  <Typography
                                    className={classes.recaptchaText}
                                    variant="caption"
                                    display="inline-block"
                                  >
                                    This site is protected by reCAPTCHA and the
                                    Google{" "}
                                    <Link
                                      href="https://policies.google.com/privacy"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className={classes.captchaLink}
                                    >
                                      Privacy Policy
                                    </Link>{" "}
                                    and{" "}
                                    <Link
                                      href="https://policies.google.com/terms"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className={classes.captchaLink}
                                    >
                                      Terms of Service
                                    </Link>{" "}
                                    apply.
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={3} />
                            </Grid>
                            <br />
                            <Grid container justifyContent="center">
                              <Grid item>
                                <Link
                                  href="/login"
                                  variant="body2"
                                  className={classes.links}
                                >
                                  Sie besitzen bereits einen Account? Einloggen
                                </Link>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </Form>
                    </div>
                  );
                }}
              </Formik>
            </div>
          ) : null}
        </Paper>
      </Container>
      <Footer />
    </>
  );
};

export default Register;
