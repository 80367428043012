import React from "react";
import { useField } from "formik";
import { TextField } from "@material-ui/core";

const MyTextField = ({
  label,
  fullWidth,
  placeholder,
  required,
  type,
  name,
  multiline,
  rows,
  disabled,
  style,
  autoComplete,
  ...props
}) => {
  const [field, meta] = useField(name);
  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <TextField
      {...field}
      variant="outlined"
      fullWidth={fullWidth}
      required={required}
      label={label}
      rows={rows}
      multiline={multiline}
      placeholder={placeholder}
      helperText={errorText}
      error={!!errorText}
      type={type}
      id={`${name}`}
      style={style}
      disabled={disabled}
      autoComplete={autoComplete}
      InputLabelProps={{
        htmlFor: `${name}`,
      }}
    />
  );
};

export default MyTextField;
