import * as Yup from "yup";

require("./globalScheme");

const companyScheme = Yup.object().shape({
  first_name: Yup.string()
    .required("Bitte geben Sie den Vornamen ein.")
    .isCharterOnly(),
  last_name: Yup.string()
    .required("Bitte geben Sie den Nachnamen ein.")
    .isCharterOnly(),
  tax_number: Yup.string().isVallidTaxNumber(),
  company: Yup.string().required("Bitte geben Sie den Firmennamen ein."),
  email: Yup.string()
    .email("Bitte geben Sie eine gültige Email ein.")
    .required("Bitte geben Sie eine Email an."),
  street: Yup.string().required("Bitte geben Sie Ihre Straße ein."),
  postal_code: Yup.string()
    .isValidPostalCode()
    .required("Bitte geben Sie Ihre Postleitzahl ein."),
  city: Yup.string().required("Bitte geben Sie Ihren Ort ein."),
  password: Yup.string()
    .isValidPassword()
    .required("Bitte geben Sie ein Passwort ein."),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Bitte geben Sie Ihr Passwort nochmal ein."),
  privacy: Yup.array()
    .min(1, "Bitte bestätigen Sie die AGB")
    .required("Bitte bestätigen Sie die AGB")
    .of(Yup.mixed().oneOf(["accepted"])),
  revocation: Yup.array()
    .min(
      1,
      "Bitte bestätigen Sie die Widerrufsbelehrung und die Datenschutzerklärung"
    )
    .required(
      "Bitte bestätigen Sie die Widerrufsbelehrung und die Datenschutzerklärung"
    )
    .of(Yup.mixed().oneOf(["accepted"])),
});

const privateScheme = Yup.object().shape({
  first_name: Yup.string()
    .required("Bitte geben Sie den Vornamen ein.")
    .isCharterOnly(),
  last_name: Yup.string()
    .required("Bitte geben Sie den Nachnamen ein.")
    .isCharterOnly(),
  email: Yup.string()
    .email("Bitte geben Sie eine gültige Email ein.")
    .required("Bitte geben Sie eine Email an."),
  street: Yup.string().required("Bitte geben Sie Ihre Straße ein."),
  postal_code: Yup.string()
    .isValidPostalCode()
    .required("Bitte geben Sie Ihre Postleitzahl ein."),
  city: Yup.string().required("Bitte geben Sie Ihren Ort ein."),
  password: Yup.string()
    .isValidPassword()
    .required("Bitte geben Sie ein Passwort ein."),

  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Bitte geben Sie Ihr Passwort nochmal ein."),
  privacy: Yup.array()
    .min(1, "Bitte bestätigen Sie die AGB")
    .required("Bitte bestätigen Sie die AGB")
    .of(Yup.mixed().oneOf(["accepted"])),
  revocation: Yup.array()
    .min(
      1,
      "Bitte bestätigen Sie die Widerrufsbelehrung und die Datenschutzerklärung"
    )
    .required(
      "Bitte bestätigen Sie die Widerrufsbelehrung und die Datenschutzerklärung"
    )
    .of(Yup.mixed().oneOf(["accepted"])),
});

export { privateScheme, companyScheme };
